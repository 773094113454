import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'giving-date-range',
  templateUrl: './giving-date-range.component.html',
  styleUrls: ['./giving-date-range.component.scss'],
})
export class GivingDateRangeComponent implements OnInit {


  // Better dat arange picker
  // https://openbase.com/js/ngx-daterangepicker-material


  @Output() startDateChanged = new EventEmitter<string>();
  @Output() endDateChanged = new EventEmitter<string>();

  start: Date = new Date();
  end: Date = new Date();
  buttonText = "Change Date";
  showPickers: boolean = false;
  constructor() { }

  ngOnInit() {}

  // https://stackoverflow.com/questions/23593052/format-javascript-date-as-yyyy-mm-dd
  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }
  


  public selectLastMonth(): void {    
    
    const lastMonth = moment().clone().subtract(1, "month");
    const firstDay = lastMonth.clone().startOf("month");
    const lastDay = lastMonth.clone().endOf("month");
    this.start =  new Date(this.formatDate(firstDay));
    this.end = new Date(this.formatDate(lastDay));
    this.emitDates();
  }

  public selectThisMonth(): void {
    const firstDay = moment().clone().startOf("month");
    const lastDay = moment().clone();
    this.start =  new Date(this.formatDate(firstDay));
    this.end = new Date(this.formatDate(lastDay));
    this.emitDates();
  }
  public selectLastThirtyDays(): void {
    this.filterSelectionByDaysAgo(29);
  }

  public selectLastSevenDays(): void {
    this.filterSelectionByDaysAgo(6);
  }

  public selectPlusOne(val): void {    
    let endDate = moment(this.end).clone();
    let startDate = moment(this.start).clone();
    endDate = endDate.add(val, "days");
    startDate = startDate.add(val, "days");
    this.start =  new Date(this.formatDate(startDate));
    this.end = new Date(this.formatDate(endDate));
    this.emitDates();
  }

  private filterSelectionByDaysAgo(daysAgo: number): void {
    const endDate = moment().clone();
    const startDate = endDate.clone().subtract(daysAgo, "days");
    this.start =  new Date(this.formatDate(startDate));
    this.end = new Date(this.formatDate(endDate));
    this.emitDates();
  }

  public selectYesterday(): void {
    const yesterday = moment().clone().subtract(1, "days");
    this.start =  new Date(this.formatDate(yesterday));
    this.end = new Date(this.formatDate(yesterday));
    this.emitDates();
  }

  public selectToday(): void {
    const today = moment().clone();
    this.start =  new Date(this.formatDate(today));
    this.end = new Date(this.formatDate(today));
    this.emitDates();
  }



  emitDates(){
    this.startDateChanged.emit(this.formatDate(this.start));
    this.endDateChanged.emit(this.formatDate(this.end));
  }

  startDateSelected($event) {
    console.log("start seelceted.... " );
    this.start = $event;
    this.startDateChanged.emit(this.formatDate(this.start));//.toISOString().substr(0, 10));
    console.log($event);
  } 

  endDateSelected($event) {
    this.end = $event;
    this.endDateChanged.emit(this.formatDate(this.end));//.toISOString().substr(0,10));
  }

  togglePickers() {
    this.showPickers = !this.showPickers;
    if (this.showPickers) {
      this.buttonText = "Hide";
    }
    else 
    {
      this.buttonText = "Change Date";
    }
  }

}

