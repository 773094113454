import { AuthGuardService } from "./providers/auth-guard.service";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CheckTutorial } from "./providers/check-tutorial.service";

const routes: Routes = [
  // {
  //   path: '**',
  //   redirectTo: '/offline-page'
  // },
  {
    path: "",
    redirectTo: "/reporting-home",
    pathMatch: "full",
  },
  {
    path: "distribution",
    loadChildren: () =>
      import("./pages/reporting/diocese/distribution/distribution.module").then(
        (m) => m.DistributionPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "parish-summaries",
    loadChildren: () =>
      import(
        "./pages/reporting/diocese/parish-summaries/parish-summaries.module"
      ).then((m) => m.ParishSummariesPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: "parish-accounts",
    loadChildren: () =>
      import("./pages/parish-accounts/parish-accounts.module").then(
        (m) => m.ParishAccountsPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "reporting-home",
    loadChildren: () =>
      import("./pages/reporting-home/reporting-home.module").then(
        (m) => m.ReportingHomePageModule
      ),
  },
  {
    path: "adelaide",
    // redirectTo: '/to/adelaide',
    redirectTo: "maintenance",
    pathMatch: "full",
  },
  {
    path: "map",
    loadChildren: () =>
      import("./pages/map/map.module").then((m) => m.MapModule),
  },
  // {
  //   path: 'tutorial',
  //   //Component: TutorialModule
  //   loadChildren: () => import('./pages/tutorial/tutorial.module').then(m => m.TutorialModule)
  // },
  // {
  //   path: 'listpaymethods',
  //   loadChildren: () => import('./pages/pay/listpaymethods/listpaymethods.module').then( m => m.ListpaymethodsPageModule)
  // },
  {
    path: "addcard",
    loadChildren: () =>
      import("./pages/pay/addcard/addcard.module").then(
        (m) => m.AddcardPageModule
      ),
  },
  // {
  //   path: 'home',
  //   loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  // },
  // {
  //   path: 'home/:diocese',
  //   loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  // },
  // {
  //   path: 'home/:parish',
  //   loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  // },
  // {
  //   path: 'confirmpayment/:churchid/:churchval/:dioceseid/:dioceseval/:thirdid/:thirdval/:total/:token',
  //   loadChildren: () => import('./pages/confirmpayment/confirmpayment.module').then( m => m.ConfirmpaymentPageModule)
  // },
  // {
  //   path: 'givenow/:type',
  //   loadChildren: () => import('./pages/givenow/givenow.module').then( m => m.GivenowPageModule)
  // },
  {
    path: "readings",
    loadChildren: () =>
      import("./pages/readings/readings.module").then(
        (m) => m.ReadingsPageModule
      ),
  },
  {
    path: "church-details/:churchid",
    loadChildren: () =>
      import("./pages/church-details/church-details.module").then(
        (m) => m.ChurchDetailsPageModule
      ),
  },
  {
    path: "app-home",
    loadChildren: () =>
      import("./pages/app-home/app-home.module").then(
        (m) => m.AppHomePageModule
      ),
  },
  {
    path: "news",
    loadChildren: () =>
      import("./pages/news/news.module").then((m) => m.NewsPageModule),
  },
  {
    path: "events",
    loadChildren: () =>
      import("./pages/events/events.module").then((m) => m.EventsPageModule),
  },
  {
    path: "podcasts",
    loadChildren: () =>
      import("./pages/podcasts/podcasts.module").then(
        (m) => m.PodcastsPageModule
      ),
  },
  {
    path: "legals",
    loadChildren: () =>
      import("./pages/legals/legals.module").then((m) => m.LegalsPageModule),
  },
  {
    path: "privacy-policy",
    loadChildren: () =>
      import("./pages/privacy-policy/privacy-policy.module").then(
        (m) => m.PrivacyPolicyPageModule
      ),
  },
  {
    path: "tos",
    loadChildren: () =>
      import("./pages/tos/tos.module").then((m) => m.TosPageModule),
  },
  {
    path: "to",
    loadChildren: () =>
      import("./pages/to/to.module").then((m) => m.ToPageModule),
  },
  {
    path: "to/:diocese",
    loadChildren: () =>
      import("./pages/to/to.module").then((m) => m.ToPageModule),
  },
  {
    path: "to/:diocese/:parish",
    loadChildren: () =>
      import("./pages/to/to.module").then((m) => m.ToPageModule),
  },
  {
    path: "transaction-summary-page",
    loadChildren: () =>
      import(
        "./pages/transaction-summary-page/transaction-summary-page.module"
      ).then((m) => m.TransactionSummaryPagePageModule),
  },
  // {
  //   path: 'media',
  //   loadChildren: () => import('./pages/media/media.module').then( m => m.MediaPageModule)
  // },
  {
    path: "offline-page",
    loadChildren: () =>
      import("./pages/offline-page/offline-page.module").then(
        (m) => m.OfflinePagePageModule
      ),
  },
  {
    path: "welcome-tutorial",
    loadChildren: () =>
      import("./pages/welcome-tutorial/welcome-tutorial.module").then(
        (m) => m.WelcomeTutorialPageModule
      ),
  },
  {
    path: "error-page",
    loadChildren: () =>
      import("./pages/error-page/error-page.module").then(
        (m) => m.ErrorPagePageModule
      ),
  },
  {
    path: "maintenance",
    loadChildren: () =>
      import("./pages/maintenance/maintenance.module").then(
        (m) => m.MaintenancePageModule
      ),
  },
  {
    path: "user-management",
    loadChildren: () =>
      import("./pages/user-management/user-management.module").then(
        (m) => m.UserManagementPageModule
      ),
    //,canActivate: [AuthGuardService]
  },
  {
    path: "parish-recurring",
    loadChildren: () =>
      import("./pages/reporting/parish-recurring/parish-recurring.module").then(
        (m) => m.ParishRecurringPageModule
      ),
    //,canActivate: [AuthGuardService]
  },
  {
    path: "collections",
    loadChildren: () =>
      import("./pages/collections/collections.module").then(
        (m) => m.CollectionsPageModule
      ),
  },
  {
    path: "admin-special",
    loadChildren: () =>
      import("./pages/admin-special/admin-special.module").then(
        (m) => m.AdminSpecialPageModule
      ),
  },
  {
    path: "myparish",
    loadChildren: () =>
      import("./pages/myparish/myparish.module").then(
        (m) => m.MyparishPageModule
      ),
  },
  {
    path: "parish-only",
    loadChildren: () =>
      import("./pages/parish-only/parish-only.module").then(
        (m) => m.ParishOnlyPageModule
      ),
  },
  {
    path: "messaging",
    loadChildren: () =>
      import("./pages/messaging/messaging.module").then(
        (m) => m.MessagingPageModule
      ),
  },
  {
    path: "customer",
    loadChildren: () =>
      import("./pages/customer/customer.module").then(
        (m) => m.CustomerPageModule
      ),
  },
  {
    path: "customers",
    loadChildren: () =>
      import("./pages/customers/customers.module").then(
        (m) => m.CustomersPageModule
      ),
  },
  {
    path: "worship-time",
    loadChildren: () =>
      import("./pages/worship-time/worship-time.module").then(
        (m) => m.WorshipTimePageModule
      ),
  },
  {
    path: "parish-admin",
    loadChildren: () =>
      import("./pages/parish-admin/parish-admin.module").then(
        (m) => m.ParishAdminPageModule
      ),
  },
  {
    path: "parish-config",
    loadChildren: () =>
      import("./pages/parish-config/parish-config.module").then(
        (m) => m.ParishConfigPageModule
      ),
  },
  {
    path: 'news-add',
    loadChildren: () => import('./pages/news-add/news-add.module').then( m => m.NewsAddPageModule)
  },
  {
    path: 'news-feeds-add',
    loadChildren: () => import('./pages/news-feeds-add/news-feeds-add.module').then( m => m.NewsFeedsAddPageModule)
  },
  {
    path: 'events-add',
    loadChildren: () => import('./pages/events-add/events-add.module').then( m => m.EventsAddPageModule)
  },
  {
    path: 'news-feeds-list',
    loadChildren: () => import('./pages/news-feeds-list/news-feeds-list.module').then( m => m.NewsFeedsListPageModule)
  },
  {
    path: 'news-edit/:id',
    loadChildren: () => import('./pages/news-edit/news-edit.module').then( m => m.NewsEditPageModule)
  },
  {
    path: 'event-edit/:id',
    loadChildren: () => import('./pages/event-edit/event-edit.module').then( m => m.EventEditPageModule)
  },
  {
    path: 'parish-details',
    loadChildren: () => import('./pages/parish-details/parish-details.module').then( m => m.ParishDetailsPageModule)
  },
  {
    path: 'reporting-distribution',
    loadChildren: () => import('./pages/reporting-distribution/reporting-distribution.module').then( m => m.ReportingDistributionPageModule)
  },
  {
    path: 'reporting-parish',
    loadChildren: () => import('./pages/reporting/parish/parish.module').then( m => m.ParishPageModule)
  },
  {
    path: 'important-message',
    loadChildren: () => import('./pages/important-message/important-message.module').then( m => m.ImportantMessagePageModule)
  },
  {
    path: 'parish-settlement',
    loadChildren: () => import('./pages/reporting/parish-settlement/parish-settlement.module').then( m => m.ParishSettlementPageModule)
  },
  {
    path: 'admin-home',
    loadChildren: () => import('./pages/admin-home/admin-home.module').then( m => m.AdminHomePageModule)
  },
  {
    path: 'diocese-admin',
    loadChildren: () => import('./pages/diocese-admin/diocese-admin.module').then( m => m.DioceseAdminPageModule)
  },
  {
    path: 'regen-receipt',
    loadChildren: () => import('./pages/regen-receipt/regen-receipt.module').then( m => m.RegenReceiptPageModule)
  },



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}