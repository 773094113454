import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor(public toastController: ToastController) { }

  async presentToastColor(message: string, color:string) {
    let col = "dark";
    if (color){
      col = color;
    }  
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      color: col,
      position: 'top'
    });
    toast.present();
  }
  async presentToast(message: string) {
    this.presentToastColor(message, 'dark');    
  }

  getElaspedTime(dateTime: any) {
    return moment(dateTime).fromNow();
  }

  downloadFile(data, filename = 'data', colNamesArray) {
    let csvData = this.ConvertToCSV(data, colNamesArray);
    //console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  downloadFeedFile(data, filename = 'data') {
    
    //console.log(csvData)
    let blob = new Blob(['\ufeff' + data], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".txt");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row =  '';//'S.No,';

    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    if (array == null) return str;
    for (let i = 0; i < array.length; i++) {
      let line = '';// (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];
        let val = array[i][head];
        if (val == null){
          val = "";
        }
        if (val.toString().includes(",")){
          line += "\"" + val + "\"" + ',' ;
        }
        else if (val.toString().includes("T",10) && (val.toString().length == 24) && (val.toString().includes(":"))){
          // is a date. (24 long, contains a T and a :  )
          line +=  array[i][head].substr(0,10) + ',' ;
        }
        else
        {
          line +=  array[i][head] + ',' ;
        }
      }
      str += line + '\r\n';
    }
    return str;
  }

  getTodaysDateMySQL(){
    var date;
    date = new Date();
    date = date.getUTCFullYear() + '-' +
        ('00' + (date.getMonth()+1)).slice(-2) + '-' +
        ('00' + date.getDate()).slice(-2) + ' ' +
        ('00' + date.getHours()).slice(-2) + ':' +
        ('00' + date.getMinutes()).slice(-2) + ':' +
        ('00' + date.getSeconds()).slice(-2);
    return date;
  }

  getTodaysDateOnlyMySQL(){
    var date;
    date = new Date();
    date = date.getUTCFullYear() + '-' +
        ('00' + (date.getMonth()+1)).slice(-2) + '-' +
        ('00' + date.getDate()).slice(-2) ;

    return date;
  }

  getDateOnlyNoHyphen(dtObject) {
    return new Date(  dtObject ).toISOString().substring(0,10).replace("-","").replace("-","");        
  }
}
