import { ToastController } from '@ionic/angular';
import { AdminUserService } from './admin-user.service';
import { DataServiceService } from './data-service.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { auth } from 'firebase';
import { id } from '@swimlane/ngx-datatable';

@Injectable({
  providedIn: "root"
})
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, public afAuth: AngularFireAuth,
    public dataService: DataServiceService, public adminUser: AdminUserService,
    public toastCtrl: ToastController) { }



       
       

  allowedRoutes: any = {
    "dioceseAdmin": 
      ['distribution', 'parish-accounts', 'parish-summaries', 'user-management',
        "worship-time",
        "parish-admin",
        "parish-config",
        'news-add',
        'news-feeds-add',
        'events-add',
        'news-feeds-list',
        'news-edit/:id',
        'event-edit/:id',
        'parish-details',
        'reporting-distribution',
        'reporting-parish',
        'important-message', 
        'parish-settlement', 
        'admin-home',
        'diocese-admin',
        'regen-receipt',
        'parish-recurring'
      ],
    "parishAdmin": 
      ['parish-report', 'parish-forecast', 'user-management',
        "worship-time",
        "parish-admin",
        "parish-config",
        'news-add',
        'news-feeds-add',
        'events-add',
        'news-feeds-list',
        'news-edit/:id',
        'event-edit/:id',
        'parish-details',
        'parish-settlement', 
        'admin-home'
      ]
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    console.log("route is: " + route);
    let routePath = route.routeConfig.path;
    let authInfo = {
      authenticated: false
    };

    if (this.afAuth.auth.currentUser == null) {
      authInfo.authenticated = false;
    }
    else {
      // debugger;
      let claimObj = this.adminUser.getClaims();
      //alert(JSON.stringify(claimObj));
      if (claimObj.admin) {
        authInfo.authenticated = true;
        return true;
      }
      else if (claimObj.dioceseAdmin) {
        if (this.allowedRoutes.dioceseAdmin.find(x => x == routePath)) {
          authInfo.authenticated = true;
        }
        else {
          authInfo.authenticated = false;
        }
      }
      else if (claimObj.parishAdmin) {
        if (this.allowedRoutes.parishAdmin.find(x => x == routePath)) {
          authInfo.authenticated = true;
        }
        else {
          authInfo.authenticated = false;
        }
      }
      else 
      {
        authInfo.authenticated = false;
      }

    }

    if (!authInfo.authenticated) {
      console.log("returning to reporting-home");
      this.accessNotAllowed()
      this.router.navigate(["reporting-home"]);

    }

    return authInfo.authenticated;
  }

  async accessNotAllowed() {
    const toast = await this.toastCtrl.create({
      message: 'You do not have the permissions to access this page!',
      position: 'middle',
      color: "danger",
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
          handler: () => {
            console.log('Close clicked');
          }
        }
      ]
    });

    await toast.present();
  }
}


// try {
//   this.afAuth.auth.currentUser.getIdTokenResult()
//     .then((idTokenResult) => {
//       //Confirm the user is an Admin.
//       if (!!idTokenResult.claims.admin) {
//         // Show admin UI.
//         console.log("user is an admin");
//         authInfo.authenticated = true;
//       }
//       else if (!!idTokenResult.claims.dioceseAdmin) {
//         this.dataService.setDioceseString('adelaide');
//         if (this.allowedRoutes.dioceseAdmin.find(x => x == routePath)) {
//           authInfo.authenticated = true;

//         }
//         authInfo.authenticated = false;

//       }
//       else if (!!idTokenResult.claims.parishAdmin) {
//         if (this.allowedRoutes.parishAdmin.find(x => x == routePath)) {
//           authInfo.authenticated = true;

//         }
//         authInfo.authenticated = false;
//       }
//       else {
//         // Show regular user UI.

//         authInfo.authenticated = false;
//         alert("You do not have access to this page");
//         authInfo.authenticated = false;
//       }
//     })
//     .catch((error) => {
//       console.log(error);
//       authInfo.authenticated = false;
//     }).finally(() => { return authInfo.authenticated; });
// }
// catch {
//   (error) => {
//     console.log(error);
//     authInfo.authenticated = false;
//   }
//   finally {
//     () => {
//       return authInfo.authenticated;
//     }
