import { AdminUserService } from './providers/admin-user.service';
import { LocationService } from './providers/location.service';
import { ThemeService } from './providers/theme.service';
import { DataServiceService } from './providers/data-service.service';
import { FirebaseService } from './providers/firebase.service';
import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
// import * as firebase from 'firebase';
import { MenuController, Platform, ToastController, AlertController, LoadingController } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Storage } from '@ionic/storage';

import { UserData } from './providers/user-data';
import { UserService } from './providers/user.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Calendar } from '@ionic-native/calendar/ngx';
// import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { environment } from './../environments/environment';
// import { FCM } from '@ionic-native/fcm/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { Network } from '@ionic-native/network/ngx';
import * as jspdf from 'jspdf';

const themes = {
  autumn: {
    primary: '#F78154',
    secondary: '#4D9078',
    tertiary: '#B4436C',
    light: '#FDE8DF',
    medium: '#FCD0A2',
    dark: '#B89876'
  },
  night: {
    primary: '#8CBA80',
    secondary: '#FCFF6C',
    tertiary: '#FE5F55',
    medium: '#BCC2C7',
    dark: '#F7F7FF',
    light: '#495867'
  },
  neon: {
    primary: '#39BFBD',
    secondary: '#4CE0B3',
    tertiary: '#FF5E79',
    light: '#F4EDF2',
    medium: '#B682A5',
    dark: '#34162A'
  },
  adelaide: {
    primary: '#1996d3',
    secondary: '#8d7956',
    tertiary: '#FF5E79',
    light: '#ffffff',
    medium: '#B682A5',
    dark: '#34162A'
  },
  sydney: {
    primary: '#c3ae83', // 013668
    secondary: '#013668',
    tertiary: '#c8211b', // c8211b
    light: '#ffffff',
    medium: '#d8d8d8',
    dark: '#34162A'
  },
  darwin: {
    primary: '#3c7a46',
    secondary: '#ffffff',
    tertiary: '#FF5E79',
    light: '#ffffff',
    medium: '#B682A5',
    dark: '#34162A'
  },
  wol: {
    primary: '#3c7a46',
    secondary: '#ffffff',
    tertiary: '#FF5E79',
    light: '#ffffff',
    medium: '#B682A5',
    dark: '#34162A'
  }
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  defaultMenuList: any = [
    {
      icon: "git-compare-outline",
      text: "Home",
      pageURL: "/reporting-home"
    }
    // ,
    // {
    //   icon: "git-compare-outline",
    //   text: "Parish Reporting(BB)",
    //   pageURL: '/parish-settlement'
    // }    
  ]

  parishUserList: any = [
    {
      icon: "git-compare-outline",
      text: "Reports",
      pageURL: "/parish-only"
    },    
    {
      icon: "document-attach-outline",
      text: "Parish Settlement Report",
      pageURL:'/parish-settlement'
    },
    {
      icon: "cafe-outline",
      text: "Special Collections",
      pageURL: "/collections"
    },
    // {
    //   icon: "people-outline",
    //   text: "Customer Only",
    //   pageURL:'/customer'
    // },
    // {
    //   icon: "people-outline",
    //   text: "Customers",
    //   pageURL:'/customers'
    // },
    {
      icon: "people-outline",
      text: "User Accounts",
      pageURL: '/user-management'
    }
  ]


  dioceseMenuListWithFeed: any = [
    {
      icon: "file-tray-full-outline",
      text: "Diocese Reporting",
      pageURL: "/distribution"
    },
    {
      icon: "bar-chart-outline",
      text: "Parish Reporting",
      pageURL: "/parish-summaries"
    },    
    {
      icon: "document-attach-outline",
      text: "Entity Settlement Report",
      pageURL:'/parish-settlement'
    },
    {
      icon: "briefcase-outline",
      text: "Parish Accounts",
      pageURL: "/parish-accounts"
    },
    {
      icon: "git-compare-outline",
      text: "Parish Recurring",
      pageURL: "/parish-recurring"
    },
    {
      icon: "eye-outline",
      text: "Investigate & Regen Receipts",
      pageURL: "/regen-receipt"
    },
    {
      icon: "cafe-outline",
      text: "Special Collections",
      pageURL: "/collections"
    },
    {
      icon: "star-outline",
      text: "Special Mass Times",
      pageURL: "/admin-special"
    },
    {
      icon: "people-outline",
      text: "User Accounts",
      pageURL: '/user-management'
    },
    {
      icon: "newspaper-outline",
      text: "Create Post",
      pageURL: "/news-add"
    },
    {
      icon: "logo-rss",
      text: "List News",
      pageURL: "/news"
    },
    {
      icon: "document-text-outline",
      text: "Create Event",
      pageURL: "/events-add"
    },
    {
      icon: "calendar-outline",
      text: "List Events",
      pageURL: "/events"
    },
    {
      icon: "list-outline",
      text: "Mass Times",
      pageURL: "/worship-times"
    }    
  ]

  dioceseMenuList: any = [
    {
      icon: "file-tray-full-outline",
      text: "Diocese Reporting",
      pageURL: "/distribution"
    },
    {
      icon: "bar-chart-outline",
      text: "Parish Reporting",
      pageURL: "/parish-summaries"
    },    
    {
      icon: "document-attach-outline",
      text: "Entity Settlement Report",
      pageURL:'/parish-settlement'
    },
    {
      icon: "briefcase-outline",
      text: "Parish Accounts",
      pageURL: "/parish-accounts"
    },
    {
      icon: "git-compare-outline",
      text: "Parish Recurring",
      pageURL: "/parish-recurring"
    },
    {
      icon: "cafe-outline",
      text: "Special Collections",
      pageURL: "/collections"
    },
    {
      icon: "eye-outline",
      text: "Investigate & Regen Receipts",
      pageURL: "/regen-receipt"
    },
    {
      icon: "star-outline",
      text: "Special Mass Times",
      pageURL: "/admin-special"
    },
    {
      icon: "people-outline",
      text: "User Accounts",
      pageURL: '/user-management'
    }
  ];

  maitlandParishList: any = [
    {
      icon: "newspaper-outline",
      text: "Create Post",
      pageURL: "/news-add"
    },
    {
      icon: "logo-rss",
      text: "List News",
      pageURL: "/news"
    },
    {
      icon: "document-text-outline",
      text: "Create Event",
      pageURL: "/events-add"
    },
    {
      icon: "calendar-outline",
      text: "List Events",
      pageURL: "/events"
    },
    {
      icon: "list-outline",
      text: "Mass Times",
      pageURL: "/worship-times"
    },
    {
      icon: "list-outline",
      text: "Button Configuration",
      pageURL: "/parish-config"
    },
    {
      icon: "list-outline",
      text: "Donation Reports",
      pageURL: "/parish-config"
    },
  ];


  buttonListParishAdmin: any = [{
    icon: "newspaper-outline",
    text: "Create Post (News, Prayer, Homily)",
    pageURL: "/news-add"
  },
  {
    icon: "calendar-outline",
    text: "Create Event",
    pageURL: '/events-add'
  },
  {
    icon: "list-outline",
    text: "View Posts and Events",
    pageURL: "/news"
  },
  {
    icon: "settings-outline",
    text: "Button Configuration",
    pageURL: '/parish-config'
  },
  {
    icon: "information-outline",
    text: "Parish Details",
    pageURL: "/parish-details"
  },
  {
    icon: "time-outline",
    text: "Mass Times",
    pageURL: '/worship-time'
  },
  // {
  //   icon: "people-outline",
  //   text: "User Management",
  //   pageURL:'/user-management'
  // },   
  {
    icon: "document-attach-outline",
    text: "Giving Donor Report",
    pageURL:'/reporting-parish'
  }
  // {
  //   icon: "document-attach-outline",
  //   text: "Diocese Donation Distribution Reports",
  //   pageURL: '/reporting-distribution'
  // }
  ];

  menuList: any = this.parishUserList;

  loggedIn = false;
  dark = false;
  userName: any;
  userPhoto: any;
  appVersion: any;
  appVersionString: string = "Web";
  firebase: any;
  fcm: any;
  splashScreen: any;
  deploy: Deploy;
  isProduction: boolean = false;
  clientName:string = "";
  userInformation:any = {legalName:"",name:""};
  constructor(
    private menu: MenuController,
    private platform: Platform,
    public router: Router,
    private ss: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private userData: UserData,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    public afAuth: AngularFireAuth,
    public userService: UserService,
    public av: AppVersion,
    public fb: FirebaseService,
    public d: Deploy,
    public dataService: DataServiceService,
    public theme: ThemeService,
    private oneSignal: OneSignal,
    private network: Network,
    private locationService: LocationService,
    private alertController: AlertController,
    public adminUser: AdminUserService,
    public loadingController: LoadingController
    //private fcmX: FCM
  ) {
    // const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    // if (isIEOrEdge){
    //   alert("This doesn't work in edge or IE" + JSON.stringify(window.navigator.userAgent));
    // }
    // alert("This doesn't work in edge or IE" + JSON.stringify(window.navigator.userAgent));
    //this.changeTheme('darwin')//
    //firebase.functions().useFunctionsEmulator('http://localhost:5002') ;
    //this.fcm = fcmX;
    this.menuList = this.defaultMenuList;
    if (environment.production) {
      this.isProduction = true;
    }

    this.presentLoading();


    //console.log = function () { }  // REMOVE console.log messages with this.
    this.deploy = d;
    this.firebase = fb;
    //this.fcm = _fcm;
    this.splashScreen = ss;
    this.appVersion = av;
    this.initializeApp();

    this.afAuth.authState.subscribe(user => {
      //debugger;
      console.info("USER LOGGED IN !");
      this.menuList = this.defaultMenuList; // clear the menu. 
      // let userid = this.afAuth.auth.currentUser.uid;
      // console.log("app component ts constructor: has the following user" + this.afAuth.auth.currentUser);
      if (this.afAuth.auth.currentUser) {
        this.loggedIn = true;
        this.userName = this.afAuth.auth.currentUser.displayName;
        this.userPhoto = this.afAuth.auth.currentUser.photoURL;


        this.adminUser.parishSelectedObserve$.subscribe(par => {
          if (par){
            if(adminUser.getClientID() == 3)
            {
              //this.menuList = this.maitlandParishList;
              this.menuList = [                    
                {
                  icon: "home-outline",
                  text: "Home",
                  pageURL: "/parish-admin"
                }                  
              ];
              this.menuList = this.menuList.concat(this.buttonListParishAdmin);
            }
          }
        })

        this.afAuth.auth.currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            //Confirm the user is an Admin.          

              //this.adminUser.setClaims(idTokenResult.claims);
              //debugger;
              let clientId = this.adminUser.getClientID();
              //this.dataService.setClient(clientId)
              console.info("Your client id is:" + clientId);
              this.clientName = this.dataService.getClientName(clientId);
              this.loading.dismiss();
              
              if (adminUser.getClaims().parishAdmin) {
                console.info("is parish admin " + adminUser.getParishID() );
                if(adminUser.getClientID() == 3)
                {
                  //this.menuList = this.maitlandParishList;
                  this.menuList = [                    
                    {
                      icon: "home-outline",
                      text: "Home",
                      pageURL: "/parish-admin"
                    }                  
                  ];
                  if (this.adminUser.getSelectedParishObj())
                  {
                    this.menuList = this.menuList.concat(this.buttonListParishAdmin);
                  }
                }
                else
                {
                  this.menuList = this.parishUserList;        
                }        
                this.userInformation = this.dataService.getParishNameStatic(adminUser.getParishID())[0];
                // if (this.)
                // this.dataService.getParishListFB().then(list => {
                //   this.userInformation = this.dataService.getParishName(200)
                // })
              }
              else 
              {
                console.info("is diocese admin");
                if (clientId == 5 || clientId == 45 || clientId == 55 )
                {
                  this.menuList = this.dioceseMenuListWithFeed;
                }
                else
                {
                  this.menuList = this.dioceseMenuList;
                }
              }
            });
      }
      else {
        this.loggedIn = false;
        this.menuList = this.defaultMenuList;
        this.loading.dismiss();
        this.router.navigateByUrl("/reporting-home");
      }

    });


  }

  loading: any;
  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...logging you in',
      duration: 2000
    });
    await this.loading.present();

    const { role, data } = await this.loading.onDidDismiss();
    console.log('Loading dismissed!');
  }

  signOut() {
    this.afAuth.auth.signOut().then(() => {
      this.userService.clear();
      this.adminUser.clear();
      this.adminUser.clearParishes();
      this.userName = "Good Giving Dashboard";
      this.menuList = this.defaultMenuList;

      window.location.reload();
    });
  }

  openURL(url) {
    if (url.startsWith('/')) {
      this.router.navigateByUrl(url);
    }
    else if (url.search("goodgiving.com.au") != -1) {
      let isApp = !document.URL.startsWith('http');
      if (isApp) {
        window.open(url, '_system');
      }
      else {
        this.router.navigateByUrl('/home') // the giving page
      }
    }
    else {
      window.open(url, '_blank');
    }
  }

  openIAP(url) {
    window.open(url, '_blank');
  }

  async offineAlert() {
    const alert = await this.alertController.create(
      {
        header: 'OFFLINE : No payment method',
        subHeader: 'To donate, please provde a payment method ',
        message: 'Click "Add Payment Method" to add payment method',
        buttons: [
          {
            text: 'Add Payment Method',
            handler: () => {
              this.router.navigate(['/addcard'])
              return;
            }
          }
        ]
      })

    await alert.present();
  };

  async ngOnInit() {
    //debugger;
    window.addEventListener('offline', async (e) => {
      alert("There is no internet connection. This app is offline")
      // const alert =  this.alertController.create(
      //   {
      //     header: 'Looks like you are offline',
      //     subHeader: 'offline ',
      //     message: 'offline',
      //     buttons: [
      //      {
      //        text: 'OK',
      //        handler: () => {
      //           this.router.navigate(['/addcard'])
      //           return;
      //       }
      //     }
      //   ]
      // })

      // await alert;
      //this.router.navigateByUrl('/offline-page');
    })

    window.addEventListener('online', (e) => {
      this.router.navigateByUrl('/app-home');
    })
    // this.swUpdate.activateUpdate();

    // // this.listenForLoginEvents();

    this.swUpdate.available.subscribe(async res => {
      const toast = await this.toastCtrl.create({
        message: 'Update available!',
        position: 'bottom',
        buttons: [
          {
            text: 'Reload',
            role: 'cancel',
            handler: () => {
              console.log('Reload clicked');
              this.swUpdate.activateUpdate();
              window.location.reload();
            }
          }
        ]
      });

      await toast.present();


      // toast
      //   .onDidDismiss()
      //   .then(() => this.swUpdate.activateUpdate())
      //   .then(() => window.location.reload());
    });
  }

  initializeApp() {
    // Retrieve an instance of Firebase Messaging so that it can handle background
    // messages.
    //const messaging = firebase.messaging();
    // this.platform.ready().then(() => {

    //   this.locationService.checkLocationEnabled().then( ret => {
    //     if (ret) {
    //       this.locationService.getLocationCoordinates().then( loc => {
    //         // this.selectedLat = loc
    //         // this.selectedLon = loc.coords.longitude;
    //         //console.log("XXDEFAULT LOACTION IS : " + this.selectedLon + "," + this.selectedLat);
    //         console.log("XXDEFAULT LOACTION IS : ${J "  + JSON.stringify(loc) );
    //         //this.updateList();
    //       });;
    //     }
    //     else {
    //       this.locationService.requestGPSPermission().then( x => {
    //         console.log("requested perission")
    //       })
    //     }
    //   });

    //   this.deploy.getConfiguration().then( x => {
    //     //alert("config is " + JSON.stringify(x));
    //   })

    //   this.deploy.checkForUpdate().then( (d) => {
    //     //alert("check for update returns "  + JSON.stringify(d));
    //     if (d.available) {
    //       this.deploy.getCurrentVersion().then(x => {
    //         //alert( "we have an update " + JSON.stringify(x ));
    //       })

    //     }
    //   })

    //   this.statusBar.styleDefault();
    //   if (this.appVersion)
    //   {
    //     this.appVersion.getAppName();
    //     this.appVersion.getPackageName();
    //     this.appVersion.getVersionCode().then( x => {
    //       //alert("App Version is" + JSON.stringify(x))
    //       this.appVersionString = JSON.stringify(x);
    //     });
    //     this.appVersion.getPackageName();
    //   }

    //   // adding in `onesignal` push notifications
    //   this.oneSignal.startInit('42ded706-039b-49e9-80de-08ee69befad0', '1054329840810');

    //   this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);

    //   this.oneSignal.handleNotificationReceived().subscribe((not) => {
    //     // do something when notification is received
    //     alert("notification recieved is " + JSON.stringify(not));
    //   });

    //   this.oneSignal.getIds().then( ids => {
    //     //alert("NOTIFICATION user id is " + ids.userId + "  pushToken  " + ids.pushToken );
    //   });


    //   this.oneSignal.handleNotificationOpened().subscribe((not) => {
    //     // do something when a notification is opened
    //     alert("notification opened is " + JSON.stringify(not));
    //     //alert("notification has arrived with payload redirect.. ")
    //   });

    //   this.oneSignal.endInit();

    //   console.log("got to the end of initApp()");

    //   this.splashScreen.hide();
    //   // alert("calling lottie");
    //   // this.lottie.show('https://assets1.lottiefiles.com/packages/lf20_D8tJsf.json', false, 1024, 768)
    //   // .then((res: any) => alert("lottie then:" + res))
    //   // .catch((error: any) => alert("error" + error));
    // });
  }


  logout() {
    this.afAuth.auth.signOut().then((val) => {
      this.loggedIn = false;
      this.userName = null;
      this.userPhoto = null;
      this.userService.clear();
      try {
        this.afAuth.auth.currentUser = null;
      }
      catch (ex) {
        console.log("error logging out")
      }
    });
  }

  openTutorial() {
    this.menu.enable(false);
    this.storage.set('ion_did_tutorial', true);
    this.router.navigateByUrl('/tutorial');
  }

  changeTheme(name) {
    this.theme.setTheme(themes[name]);
  }
}
