import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { DataServiceService } from './data-service.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/internal/Subject';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { toastController } from '@ionic/core';

@Injectable({
  providedIn: 'root'
})
export class AdminUserService {
  claimsObj:any;
  authToken:any = null;
  allChurchesForUser:any = [];
  parishesForUser:any = {};
  parishNameIDsForUser:any = [];
  selectedParish:any = null;

  private parishSelectedSource = new Subject<string>();
  parishSelectedObserve$ = this.parishSelectedSource.asObservable();

  constructor(public dataService:DataServiceService, public afAuth: AngularFireAuth, public firestore:AngularFirestore, public router:Router) {
    this.afAuth.authState.subscribe(user => {
      if (this.afAuth.auth.currentUser == null) {

      }
      else {
        this.afAuth.auth.currentUser.getIdToken().then(idToken => {
          this.authToken = idToken;
          
        });
        this.afAuth.auth.currentUser.getIdTokenResult().then(tok => {
          //console.log("claims for user" + JSON.stringify(tok.claims));

          this.dataService.setClientID(tok.claims.dioceseID);
          this.setClaims(tok.claims);

          if (this.claimsObj["dioceseID"] == 3 ){
            this.router.navigateByUrl('/parish-admin')
          }
          else if ( this.claimsObj["dioceseID"] == 5 || this.claimsObj["dioceseID"] == 45 || this.claimsObj["dioceseID"] == 55 || this.claimsObj["dioceseID"] == 12 || this.claimsObj["dioceseID"] == 17)
          {
            this.router.navigateByUrl('/diocese-admin')
          }
          
        })

      }
      
    });
    

   }

  getUserName(){
    this.afAuth.auth.currentUser.email;
  }

  getAuthToken() {
    return this.authToken;
  }
  async setClaims(dat){
    if (dat){
      this.claimsObj = dat;
      let clientid = this.claimsObj.dioceseID;
      await this.dataService.setDioceseString(this.dataService.getClientName(clientid));
    }
  }

  isParishAdmin() {
    if (this.claimsObj)
    {
      return this.claimsObj.parishAdmin;
    }
    return false;
  }

  isDioceseAdmin() {
    if (this.claimsObj)
    {
      return this.claimsObj.dioceseAdmin;
    }
    return false;
  }

  isOtherEntity()
  {    
    return (["50050","50060",50050,50060].includes(this.getParishID()) );    
  }

  getClaims(){
    return this.claimsObj;
  }

  getClientID(){
    if (this.claimsObj.dioceseID)
    {
      return this.claimsObj.dioceseID;
    }
    else
    {
      console.error("There is no client id returning 0. Stack trace below");
      console.trace();  
      return 0;
    }
    
  }

  getParishID(){
    return this.claimsObj.parishID;
  }

  getClientString(){
    if(!this.claimsObj) 
    {
      return "nothing";
    }
    if (this.claimsObj.dioceseID == 5){
      return "adelaide";
    }
    else if (this.claimsObj.dioceseID == 15){
      return "darwin";
    }
    else if (this.claimsObj.dioceseID == 25){
      return "dow";
    }
    else if (this.claimsObj.dioceseID == 17){
      return "brokenbay";
    }
    else if (this.claimsObj.dioceseID == 3){
      return "maitland";
    }
    else if (this.claimsObj.dioceseID == 45){
      return "pp";
    }
    else if (this.claimsObj.dioceseID == 55){
      return "bathurst";
    }
  }
  
  clear(){
    this.claimsObj = undefined;
    this.authToken = null;
    this.allChurchesForUser = [];
    this.parishesForUser = {};
    this.parishNameIDsForUser = [];
    this.selectedParish = null;
  }

  async getClaimsForUser(email){
    //await this.afAuth.auth.
    //getUserByEmail(email); if (user.customClaims) { console.log(user.customClaims) }
  }

  getParishAdminList() {    
        return this.parishNameIDsForUser;
  }  

  getSelectedParishChurchList() {
    return this.selectedParish.churches; 
  }

  getAllChurches() {
    return this.allChurchesForUser ;

  }

  selectParish(id){
    
    this.selectedParish = this.parishesForUser[id];
    if (this.selectedParish){
      this.parishSelectedSource.next(this.selectedParish)
      return this.selectedParish;
    }
    else
    {
      alert("this parish is not available, please contact support");
    }
  }

  getSelectedParishID(){
    if (this.selectedParish)
    {
      return this.selectedParish.parishID;
    }
    else
    {
      return 0;
    }
  }

  getSelectedParishObj() {
    return this.selectedParish;
  } 

  setSelectedParishObj(ob) {
    this.selectedParish = ob;
  }

  clearParishes(){
    this.parishesForUser = {};
    this.parishNameIDsForUser = [];
    this.allChurchesForUser = [];
  }

  async loadDioParish() {
    if (!this.isDioceseAdmin())
    {
      return false;
    }
    let myPromise = new Promise((resolve, reject) => {
      let collection = this.firestore.collection("parishInfo", ref => ref.where('parishID' , '==', this.getClientID().toString()   ) );    
      collection.get()
      .subscribe((ss) => {
        
        console.log("found dio parush count " + ss.docs.length);
        if (ss.docs.length == 0) {
          console.log('No dio parishes associated with this user. ');          
          reject(false);
        } else {
          ss.docs.forEach(doc => {     
            let parish = doc.data();
            this.parishesForUser[parish.parishID] = parish;   
            this.parishNameIDsForUser.push({ID:parish.parishID, name: parish.parishName});    
            this.allChurchesForUser.push(parish.churches);
            console.log(JSON.stringify(parish));
          });
          resolve(true);
        }
      });
    });

  }


  async loadParishes() {
    // if (this.isDioceseAdmin())
    // {
    //   if (this.getClientID() != 3)
    //   return false;
    // }
    this.clearParishes();
    console.log("loading parish info for parishes with this admin");
    

    let myPromise = new Promise((resolve, reject) => {
      let parishIDList =  [];
      let collection ;
      if (this.claimsObj.dioceseAdmin){       
        collection = this.firestore.collection("parishInfo", ref => ref.where('dioceseID' , '==', this.getClientID().toString()   ) );
      }
      else
      {
        if (this.getClientID().toString() == "17") {
          parishIDList = [parseInt(this.claimsObj.parishID)]; // only one parish id per person at present.
        }   
        else {
           parishIDList =  this.claimsObj.parishAdmins.split(",");  
        }
        collection = this.firestore.collection("parishInfo", ref => ref.where('parishID', 'in', parishIDList) )     
      }
      
        collection.get()
        .subscribe((ss) => {
          
          console.log("found parush count " + ss.docs.length);
          if (ss.docs.length == 0) {
            alert('No parishes associated with this user. ');          
            reject(false);
          } else {
            ss.docs.forEach(doc => {     
              let parish = doc.data();
              this.parishesForUser[parish.parishID] = parish;   
              this.parishNameIDsForUser.push({ID:parish.parishID, name: parish.parishName});    
              this.allChurchesForUser.push(parish.churches);
              //console.log(JSON.stringify(parish));
            });
            resolve(true);
          }
        });
      });
      return myPromise;  
  }
}
